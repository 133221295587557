input[type="button"]:disabled,
input[type="submit"]:disabled,
input[type="checkbox"]:disabled,
input[type="radio"]:disabled,
button:disabled {
    cursor: not-allowed !important;
    opacity: .50
}

input[type="checkbox"]:focus,
input[type="radio"]:focus {
    outline: 2;
    outline-color: #5046E5;
    box-shadow: none !important;    
}

input[type="checkbox"]:checked:hover,
input[type="radio"]:checked:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(105, 96, 233, var(--tw-bg-opacity));
}

input[type="checkbox"]:checked:focus,
input[type="radio"]:checked:focus {
    --tw-bg-opacity: 1;
    background-color: rgba(80, 70, 229, var(--tw-bg-opacity));
}

input[type="text"]:disabled,
textarea:disabled {
    cursor: not-allowed !important;
}

[type="button"] {
    -webkit-appearance: none !important;
}

.animate-right {
    position: relative;
    animation: animateright 0.3s
}

@keyframes animateright {
    from {
        right: -50px;
        opacity: 0
    }

    to {
        right: 0;
        opacity: 1
    }
}

// removes the spinner circle for axios loading bar
#nprogress .spinner-icon {
    display: none;
}

html {
    -webkit-print-color-adjust: exact;
}

@media print {
    .print-break-inside-avoid {
       break-inside: avoid;
    }
  }