@import "../../../assets/scss/base";

.home {}

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
  }
  
  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
  
    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 125%;
    left: 50%;
    margin-left: -60px;
  
    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  /* Tooltip arrow */
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #000 transparent transparent transparent;
  }
  
  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

.dialog-design-container {

    .design-row,
    .design-column,
    .design-element {
        position: relative;

        &.pointer {
            cursor: pointer;

            >* {
                cursor: pointer;
            }
        }

        &.new {
            // border: 1px dashed black;
            outline: 1px dashed blue;
            outline-offset: 3px;

            //margin: 3px;
        }

        &.background:hover{
            background-color: #eee !important;
        }

        &.active {
            border: 1px dashed black;
            //outline: 2px dashed #444;
            //outline-offset: 3px;
            //background-color: #ddd !important;
            //padding:3px !important;
            //margin: 3px;
        }
    }

    .design-container {
        &.pointer {
            cursor: pointer;

            >* {
                cursor: pointer;
            }
        }

        &.new {
            // border: 1px dashed black;
            // outline: 1px dashed blue;
            // outline-offset: 3px;
            background-color: #eee !important;
            //padding:10px !important ;
            //margin: 3px;
        }

        &.background:hovera{
            background-color: #eee !important;
        }

        &.active {
            // border: 1px dashed black;
            //outline: 2px dashed #444;
            //outline-offset: 1px;
            //padding:7px !important ;
            //margin: 3px;
        }
    }

    .grid-container {
        border: 2px solid blue !important;
    }

    .grid-row {
        border: 2px solid red !important;
        margin: 4px !important;
    }

    .grid-column {
        border: 2px solid green !important;
        margin: 4px !important;
    }
}

.element-pop-bottom {
    height: 530px;
}

.popover-container {
    max-height: calc(100vh - 11rem);

    &-items {
        max-height: calc(100vh - 26rem);
    }
}

.actions-bar {
    top: 7.3em;
    padding-top: 1rem;
}

@media (min-width: 1536px) {
    .element-pop-bottom {
        height: 700px;
    }

    .element-pop-top {
        height: 880px;
    }
}

@media (min-width: 1300px) {
    .actions-wrapper {
        position: relative;
    }

    .actions-right {
        position: absolute;
        right: 0;
        top: 0
    }
}